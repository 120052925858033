


.MuiDataGrid-root{
    border:none;
    border-radius:0!important;
}



.dark .MuiDataGrid-columnHeaderWrapper {
    color:#ccc;
}
.light .MuiDataGrid-columnHeaderWrapper {
    color: #222;
}
.MuiDataGrid-virtualScroller{
    margin-top:36px!important;
    height:calc(100vh - 198px)!important;
}
.MuiDataGrid-columnsContainer {
    min-height: 36px !important;
    max-height: 36px !important;
    line-height: 36px !important;

}

/*This controls column header color*/
.dark .MuiDataGrid-columnsContainer {
    border-bottom: solid 1px #151515 !important;
    background-color: #090909 !important;
    border-radius: 0px !important;
}
.light .MuiDataGrid-columnsContainer {
    border-bottom: solid 1px #ddd !important;
    background-color: #f5f5f5 !important;
    border-radius: 0px !important;
}



.dark .MuiTablePagination-toolbar {
    color: #ccc !important;
}
.light .MuiTablePagination-toolbar {
    color: #222 !important;
}


.MuiTablePagination-toolbar p{
    margin-bottom:0px!important;
}
.MuiDataGrid-columnSeparator {
    color: transparent !important;
}
.MuiDataGrid-columnHeaderWrapper, .MuiDataGrid-columnHeader, .MuiDataGrid-columnsContainer, .css-tsouws-MuiDataGrid-root {
    border-radius: 0px !important;
}
.MuiTreeItem-content {
    padding-top: 2px;
    padding-bottom: 2px;
}


.dark .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
    background-color: #111!important;
}
.light .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
    background-color: #ddd!important;
}


.MuiDataGrid-cell--withRenderer:focus-within {
    outline: none !important;
}
.MuiButton-root .MuiDataGrid-cell:focus {
    outline: none !important;
}
.MuiDataGrid-cell:focus {
    outline: none !important;
}

.light .MuiDataGrid-virtualScroller{
    background-color:#fff!important;
}
.dark .MuiDataGrid-virtualScroller {
    background-color: transparent !important;
}
.dark .MuiDataGrid-cell {
    border-bottom-color: transparent !important;
    color: #ddd !important;
    font-size: .8rem;
    cursor:pointer;
}
.light .MuiDataGrid-cell {
    border-bottom-color: transparent !important;
    color: #333 !important;
    font-size: .8rem;
    cursor: pointer;
}
.MuiTreeItem-label {
 /*   font-size: .9rem !important;*/
}
.dark .MuiDataGrid-overlay {
    background: rgba(0,0,0,.3) !important;
    color: #ccc !important;
    top:36px!important;
}
.light .MuiDataGrid-overlay {
    background: #fff !important;
    color: #444 !important;
    top: 36px !important;
}
/*.MuiDataGrid-selectedRowCount{
    display:none!important;
}*/

.dark .MuiDataGrid-toolbarContainer button {
    color: #21558c;
}

