.avatar:hover {
    cursor: pointer;
}

.avatarImage {
    height: 50px !important;
    width: 50px !important;
    border-radius: 50%;
}

.avatarImageSmall {
    height: 34px !important;
    width: 34px !important;
    border-radius: 50%;
    margin-right: -10px !important;
}
.avatarImageMedium{
    height: 42px !important;
    width: 42px !important;
    border-radius: 50%;
}
.avatarImageLarge {
    height: 60px !important;
    width: 60px !important;
    border-radius: 50%;

}
.avatarImageExtraLarge {
    height: 80px !important;
    width: 80px !important;
    border-radius: 50%;
    margin-right: -10px !important;
}
.avatarImageMassive {
    height: 120px !important;
    width: 120px !important;
    border-radius: 20px!important;
    margin-right: -10px !important;
}
.avatarImageColossel {
    height: 200px !important;
    width: 200px !important;
    border-radius: 16px!important;

}
.MuiAvatarGroup-avatar, .MuiAvatar-root {
    border: none !important;
}
.avatarImageTiny {
    height: 30px !important;
    width: 30px !important;
    border-radius: 50%;
    margin-right:4px;
}

    .avatarImageTiny.MuiAvatar-root {
        font-size: unset !important;
    }

.avatargroup {
    overflow: visible !important;
    justify-content: end !important;
    height: 34px !important;
    width: 34px !important;
}

.avatargroupTiny {
    overflow: visible !important;
    height: 30px !important;
    width: 30px !important;
    justify-content: normal !important;
}

.hoverPointer:hover{
    cursor:pointer;
    opacity:.9;
}
.vertical-center-parent {
    position: relative;
}

.vertical-center-child {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.vertical-center-child {
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.horizontal-center-child {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.horizontal-left-child {
    margin: 0;
    position: absolute;
    left: 20%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.horizontal-right-child {
    margin: 0;
    position: absolute;
    left: 80%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.marginbottom15{
    margin-bottom:15px;
}

.selectedItem div.MuiTreeItem-content {
    background-color: rgba(0,0,0,.3) !important;
}
/*.selectedItem ul div {
    background-color: #333!important;
}*/
.selectedItemSub {
    background-color: rgba(0,0,0,.3) !important;
}
.loginInput fieldset{
    background-color: rgba(255,255,255,0.1)!important;
}
.ls-react-pdf {
    margin-bottom: 15px;
}
    .ls-react-pdf canvas{
        margin-left:auto;
        margin-right:auto;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: #ddd !important;
        -webkit-padding-start: 5px;
    }


/*input:-webkit-autofill {
    -webkit-background-clip: text;
    color:white;
}*/
.prettyScroll::-webkit-scrollbar {
    display: none;
}

.prettyScroll, .MuiDataGrid-virtualScrollerContent {
    overflow-y: auto;
    overflow-x: hidden!important;
    -ms-overflow-style: none!important; /* IE and Edge */
    scrollbar-width: none!important;
}
.ql-header.ql-picker,
.ql-size.ql-picker {
    color: #eee !important;
}
.ql-snow .ql-stroke {
    stroke: #eee !important;
}
.dark .ql-snow .ql-stroke {
    stroke: #ccc !important;
}
.dark .ql-header.ql-picker,
.dark .ql-size.ql-picker {
    color: #ccc !important;
}
.dark .ql-snow .ql-stroke {
    stroke: #ccc !important;
}
.ql-toolbar {
    background-color: #384370 !important;
    border: none !important;
}
.dark .ql-toolbar {
    background-color: #151515 !important;
}
.ql-container {
    border: none !important;
    font-size: unset !important;
}
.datePicker{
   
    margin-top: 8px;
}
.css-lp1mf4-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    background-color:#444;
}
.css-1kfisnz-MuiButtonBase-root-MuiPickersDay-root{
    color:#444;
}
.css-169iwlq-MuiCalendarPicker-root {
    color: #444;
}
.css-1rki7fh-MuiInputBase-root-MuiInput-root {
    color: #ccc;
}

.light .css-wfa368-MuiInputBase-input-MuiInput-input{
    color:#444!important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

:root {
    --fc-small-font-size: .85em;
    --fc-page-bg-color: #fff;
    --fc-neutral-bg-color: #000;
    --fc-neutral-text-color: #808080;
    --fc-border-color: #666;
    --fc-button-hover-bg-color: #666;
    --fc-button-hover-border-color: #666;
    --fc-button-active-bg-color: #35548b;
    --fc-button-active-border-color: #35548b;
    --fc-event-bg-color: #3788d8;
    --fc-event-border-color: #3788d8;
    --fc-event-text-color: #fff;
    --fc-event-selected-overlay-color: rgba(0, 0, 0, 0.25);
    --fc-more-link-bg-color: #d0d0d0;
    --fc-more-link-text-color: inherit;
    --fc-event-resizer-thickness: 8px;
    --fc-event-resizer-dot-total-width: 8px;
    --fc-event-resizer-dot-border-width: 1px;
    --fc-non-business-color: rgba(215, 215, 215, 0.3);
    --fc-bg-event-color: rgb(143, 223, 130);
    --fc-bg-event-opacity: 0.3;
    --fc-highlight-color: #555;
    --fc-today-bg-color: rgba(255, 220, 40, 0.15);
    --fc-today-bg-color: #000;
    --fc-now-indicator-color: red;
    --fc-page-bg-color: #000;
    --fc-list-event-hover-bg-color: #35548b;
}


.light .fc .fc-toolbar-title {
    font-size: .9rem;
    color: #444;
    font-weight: 600;

}
.dark .fc .fc-toolbar-title {
    font-size: .9rem;
    color: #ddd;
    font-weight: 600;

}

.light .fc .fc-button {
    padding: 3px 6px !important;
    font-size: .8rem;
    background-color: transparent;
    border: none !important;
    color: #444;
}

.light .fc .fc-button .fc-icon {
    vertical-align: middle;
    font-size: 1.2em;
    margin-bottom: 3px;
    background-color: transparent;
    border: none !important;
    color: #444;
}

.dark .fc .fc-button {
    padding: 3px 6px !important;
    font-size: .8rem;
    background-color: transparent;
    border: none !important;
    color: #ccc;
}

.dark .fc .fc-button .fc-icon {
    vertical-align: middle;
    font-size: 1.2em;
    margin-bottom: 3px;
    background-color: transparent;
    border: none !important;
    color: #ccc;
}

.light .fc-col-header-cell, .light .fc-timegrid-axis {
    background-color: #35548b;
}
.light .fc-col-header-cell a{
    color: #ddd;
}
.dark .fc-col-header-cell, .dark .fc-timegrid-axis {
    background-color: #000;
}

.dark .fc-col-header-cell a {
    color: #ddd;
}

.dark .fc .fc-scrollgrid, .dark .fc .fc-scrollgrid td, .dark .fc .fc-scrollgrid tr, .dark .fc .fc-list, .dark .fc .fc-list-table td {
    border-color: #444!important;
}
.light .fc .fc-scrollgrid, .light .fc .fc-scrollgrid td, .light .fc .fc-scrollgrid tr, .light .fc .fc-list, .light .fc .fc-list-table td {
    border-color: #bbb !important;
}
.light .fc .fc-daygrid-day-number, .fc a {
    text-decoration: none;
    color: #555;
}

.dark .fc .fc-daygrid-day-number, .fc a {
    text-decoration: none;
    color:#ccc;
}

.light .fc .fc-timegrid-axis-frame, .light .fc .fc-timegrid-slot-label {
    background-color: #d9d9d9;
}

.dark.fc .fc-timegrid-axis-frame, .dark .fc .fc-timegrid-slot-label {

}

.light .fc-theme-standard td{
    background-color:#eee;
}
.dark .fc-theme-standard td {
    background-color: #000;

}
.light .fc .fc-daygrid-day.fc-day-today {
    background-color: #ddd;
}

.light .fc .fc-daygrid-day-events a {
    color: #35548b;
}

.fc table {
    font-size: 14px !important;
}

.fc th {
    font-weight: 500 !important;
}

tr.fc-list-event:hover {
    background-color: #666 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: .5em;
}

.typing {
    height: .7em;
    position: relative;
    padding: 8px;
    padding-bottom:16px;
    margin-left: 5px;
    border-radius: 20px;
    
}

.typing__dot {
    float: right;
    width: 6px;
    height: 6px;
    margin: 0 4px;
   /* background: #8d8c91;*/
    background: #ddd;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
}

    .typing__dot:nth-child(1) {
        animation-delay: 0.4s;
    }

    .typing__dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .typing__dot:nth-child(3) {
        animation-delay: 0s;
    }

@keyframes loadingFade {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.8;
    }

    100% {
        opacity: 0;
    }
}
.MuiAccordionSummary-content{
    margin:0px!important;
}
.MuiAccordionSummary-root{
    min-height:unset!important;
}
.dark .linkPreview:hover{
    background-color:#0a0a0a!important;
}
.light .linkPreview:hover {
    background-color: #f0f0f0 !important;
}
.linkPreview h3{
    font-size:1rem;
}
.linkPreview .Secondary{
    font-size:.9rem;
}
.linkPreview .SiteDetails{
    margin-top:6px;
}
.dark .emoji-picker-react{
    background:#191919!important;
    box-shadow:none!important;
    border-color:#333!important;
    padding-top:15px!important;
}
.dark .emoji-group:before {
    background: #191919 !important;
}
.dark .emoji-search {
    background: #333 !important;
    border-color:#444!important;
    color:#ccc!important;
}
.emoji-categories{
    display:none!important;
}
.emoji-scroll-wrapper {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important;
}
.emoji-scroll-wrapper::-webkit-scrollbar{
    display:none!important;
}

.fc-scroller, .MuiDataGrid-virtualScroller {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important;
}

    .fc-scroller::-webkit-scrollbar, .MuiDataGrid-virtualScroller::-webkit-scrollbar {
        display: none !important;
    }
.fc-timegrid-body, .fc-timegrid-slots, .fc-timegrid-slots table, .fc-timegrid-slot, .fc-scrollgrid-sync-table, .fc-daygrid-body, .fc-col-header {
    width: 100% !important;
}
.fc-col-header {
    width:100%!important;
}
.fullWidth{
    width:fill-available;
}

.Common:hover{
    background-color:unset!important;
    opacity:.8!important;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.datetimepicker input{
    color: white!important;
}

.dark input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  /*  background: transparent;*/
    filter: invert(1);
}
/*::-webkit-calendar-picker-indicator {
    filter: invert(1);
}*/

.css-16aenxq-MuiPaper-root-MuiAccordion-root:before,
.css-1czu8lg-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent !important;
}

.css-1xpu9hr-MuiButtonBase-root-MuiTab-root.Mui-selected {
/*    color: rgb(50,71,140)!important;*/
}
.MuiTabs-flexContainer .Mui-selected {
 /*   background: #415ba0 !important;*/
    max-width: unset !important;
    color: white !important;
}
.dark .MuiTabs-flexContainer .Mui-selected {
    background: transparent!important;
    color: white !important;
}
.MuiTooltip-tooltip {
    background: #444 !important;
}
.subTab .MuiTabs-flexContainer .Mui-selected {
    background: #e6e6e6 !important;
    color: rgb(50,71,140) !important;

}

.dark .subTab .MuiTabs-flexContainer .Mui-selected {
    background: #151515 !important;
    color: white !important;

}
/*.MuiDialogContent-root{
    padding-top:8px!important;
}*/
.MuiButton-endIcon{
    margin-left:0px!important;
}
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
    -webkit-text-fill-color: #999 !important;
}
.MuiInputLabel-root.Mui-focused:before{
    color: white!important;
}
.postText {
    border-radius: 8px;
}
.postText__input {
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 0.375rem;
    padding-right: 0.75rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
 /*   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;*/
}
.postTextDark__input {
    border: none;
    border-radius:8px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding-top: 0.375rem;
    padding-right: 0.75rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
  /*  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;*/
    color: rgb(204, 204, 204);
}
.postText__input:focus-visible, .postTextDark__input:focus-visible {
    outline: 0;
   /* box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);*/
}
.postTextDark__highlighter, .postText__highlighter {
    padding-top: 0.375rem;
    padding-right: 0.75rem;
    padding-bottom: 0.375rem;
    padding-left: 0.75rem;
    border:none;
}
.postText__suggestions{
    padding: 0px 4px !important;
    max-height: 150px!important;
    font-size:.8rem!important;
    overflow-y:auto;
}
.postText__suggestions list {
    padding: 0px 4px !important;
    max-height: 150px !important;
}
.postTextDark__suggestions {
    background-color: #111!important;
    padding: 0px 4px!important;
}

.dark .MuiSwitch-track{
    background-color:#666;
}
.rhap_container{
    background-color:unset;
    box-shadow:none;
}
.rhap_time{
    color:#999;
}
.Mui-focusVisible{
    background-color: transparent!important;
}
.linkframe iframe{
    height: 250px!important;
    width:100%;
    border-radius: 6px;

}
.MuiSlider-markLabel {
    color: #aaa !important;
    font-size: .7rem !important;
}

.MuiSlider-markLabelActive {
    color: #333 !important;
    font-weight: 500 !important;
    text-decoration: underline !important;
}

.dark .MuiSlider-markLabel{
    color:#bbb!important;
    font-size:.7rem!important;
}
.dark .MuiSlider-markLabelActive {
    color: #eee !important;
    font-weight: 500 !important;
    text-decoration: underline!important;
    margin-bottom:-6px;
}
.noBorderRadius, .MuiToggleButtonGroup-groupedHorizontal {
    border-radius: 0px !important;
}
.MuiToggleButton-root.Mui-selected {
    border: 1px solid #32478C!important;
    background-color:unset!important;
}
.MuiChip-labelSmall {
    font-size: 0.7rem;
}
/*.MuiTab-root{
    padding:8px!important;
}*/
.MuiSpeedDialAction-fab:hover {
    background-color: #00205e!important;
}
.MuiTabs-scrollButtons{
    color:#bbb!important;
}
.MuiBottomNavigationAction-root {
    color: #ddd;
    padding-top:12px;
}
.dark .MuiBottomNavigationAction-root {
    color: #ddd;
}
.MuiBottomNavigationAction-root.Mui-selected {
    color: #ddd;
    border-top: 3px solid #384370;
    background: #1d1d1d;
}
body {
    padding-bottom: env(safe-area-inset-bottom);
}
.postvideo video{
    margin-bottom:-8px;
}
/*.song .MuiCardMedia-root {
    background-color: rgb(200,200,200);
    background-blend-mode: multiply;
}*/
/*.song .dark .MuiCardMedia-root {
    background-color: rgb(150,150,150);
    background-blend-mode: multiply;
}*/
.MuiBadge-badge {
    font-size: .675rem;
    min-width: 18px;
    height: 18px;
    width: 18px;
    border-radius: 9px;
    padding: 0px;
}
.bottomNav .MuiBadge-badge{
    font-size:.625rem;
    min-width:16px;
    height:16px;
    width:16px;
    border-radius:8px;
    padding: 0px;
}
.MuiBadge-dot {
    min-width: 12px !important;
    height: 12px !important;
    width: 12px !important;
    border-radius: 6px !important;
}
.MuiTabScrollButton-root.Mui-disabled{
    display:none;
}
.MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: #666;
}
.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.tui-image-editor-header, .tui-image-editor-help-menu {
    display: none !important;
}
.tui-image-editor-container{
    vertical-align:top!important;
}
    .tui-image-editor-container .tui-image-editor-main{
        top:8px !important;
    }
    .tui-image-editor-container .tui-image-editor-wrap .tui-image-editor-size-wrap .tui-image-editor-align-wrap {
         display: unset!important;
        vertical-align: middle;
    }

:root {
    --app-height: 100%;
}

html,
body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    height: var(--app-height);
}
.react-colorful {
    width:auto!important;
}
    .react-colorful, .react-colorful__saturation, .react-colorful__last-control {
        border-radius: 0px !important;
    }
.react-colorful__pointer{
    width:16px!important;
    height:16px!important;
}
.MuiSlider-root{
    padding:12px 0!important;
}
.MuiSwitch-track {
    background-color: #384370;
}
.MuiSwitch-switchBase {
    color: #384370;
}
.MuiMenu-paper{
    background-color: transparent;
}
 .Mui-focused {
    color: #444!important;
}
.dark .Mui-focused{
    color:#bbb!important;
}
.dark .MuiPickerStaticWrapper-content {
    background-color: #151515;
    color: #ddd;
    border:1px solid #191919;
    border-radius:8px;
}
    .dark .MuiPickerStaticWrapper-content .MuiPickersCalendarHeader-labelContainer {
        color: #eee !important;
    }
.dark .MuiButtonBase-root.MuiPickersDay-root{
    color: #ddd;
    background-color: transparent;
}
.dark .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
    color: #444;
    background-color: #ccc;
}
.MuiYearPicker-root {
    font-size: .8rem;
}
.dark .MuiYearPicker-root{
    color: #aaa;
}
.PrivatePickersYear-yearButton {
    font-size: .9rem;
    margin: 0px;
}
.hoverdropzone {
    border: 4px dashed green;
    opacity: .8;
}
.hoverdropzoneBusy {
    opacity: .7;
}